import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import {
  FunctionComponent,
  useState,
  memo,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import useLinkProps, { useResolveLink } from '../../../hooks/use-link-props';
import DividerTop from '../../ui/icons/dividers/top';
import BPImage from '../../ui/image';
import FeatureLink from '../../ui/links/featured';
import TextLink from '../../ui/links/text';

import classes from './top-brands.module.css';

const TopBrands: FunctionComponent<{ brands: Array<TBrand> }> = ({
  brands,
}) => {
  const ref = useRef<HTMLElement>(null);
  const [shouldGetImages, setShouldGetImages] = useState(false);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const getLink = useResolveLink();

  useEffect(() => {
    const watcher: IntersectionObserverCallback = (entries) => {
      if (entries[0].intersectionRatio !== 0) {
        setShouldGetImages(true);
      }
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    let observer: IntersectionObserver;

    if (typeof IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver(watcher, options);

      ref.current && observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const brandPages = useMemo(() => {
    const pages = Math.ceil(brands.length / 8);

    return Array(pages)
      .fill(0)
      .map((v, i) => i);
  }, [brands]);

  return (
    <section className={cx('text-center')} ref={ref}>
      <DividerTop
        variantMobile="up"
        className={cx('text-neutral-10', 'w-full', 'lg:h-7')}
      />
      <div className={cx('bg-neutral-10')}>
        <div className="px-6 lg:px-0 container m-auto py-16 lg:py-20">
          <h2 className="text-2xl font-bold px-6">
            {t('home:top-brands.title')}
          </h2>
          <p className="text-base px-6">{t('home:top-brands.sub-title')}</p>
          <div className={classes.slider}>
            <ul
              className={cx('mt-4 lg:mt-4 -mx-2', classes.list)}
              style={{ transform: `translate(-${page * 100}%, 0)` }}
            >
              {brands.map((item) => {
                const search = new URLSearchParams();
                search.set('brand', item.slug);

                return (
                  <li
                    className={cx(classes.item, 'py-1 px-2 lg:p-2')}
                    key={item.id}
                  >
                    <Link
                      href={getLink('models', search.toString())}
                      passHref
                      legacyBehavior
                    >
                      <TextLink
                        className={cx(
                          'text-neutral-100',
                          'font-bold',
                          'lg:text-2xl',
                          'leading-6'
                        )}
                      >
                        {item.name}
                      </TextLink>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex justify-center align-middle pt-3 pb-5">
            {brandPages?.map((value, index) => (
              <div
                role="presentation"
                key={value}
                className={cx(
                  classes.dot,
                  'cursor-pointer relative overflow-hidden',
                  {
                    [classes.dotActive]: page === index,
                  }
                )}
                onClick={() => setPage(index)}
              />
            ))}
          </div>

          <Link href={getLink('brands')} passHref legacyBehavior>
            <FeatureLink className="text-base">
              {t('home:top-brands.link')}
            </FeatureLink>
          </Link>
        </div>
      </div>
      <DividerTop
        className={cx(
          'transform',
          'scale-y-invert',
          'text-neutral-10',
          'w-full',
          'lg:h-16'
        )}
      />
    </section>
  );
};

export default TopBrands;
